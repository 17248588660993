



import React from 'react';
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import React Router
import './App.css';
import { useLocation } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Blog from './Components/Blog/Blog';
import BlogDetail from './Components/Blog/BlogDetail';
import About from './Components/About/About';
import Ingredents from './Components/Ingredents/Ingredents';
import CardDetails from './Components/About/CardDetails';
import Consultation from './Components/Contact/Consultation';
import Shop from './Components/Shop/Shop';
import Chatbox from './Components/Chatbox/Chatbox';
import "aos/dist/aos.css";
import AOS from "aos";
import ShopDetails from './Components/Shop/Shopdetails';

import Disclaimer from './Components/Disclaimer/Disclaimer';

AOS.init({
  duration: 1000,
});
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}



function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/shopdetails/:id" element={<ShopDetails />} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
       
        <Route path="/about" element={<About/>} />
        <Route path="/card/:id" element={<CardDetails/>} />
        <Route path="/ingredients" element={<Ingredents/>} />
        <Route path="/consultation" element={<Consultation/>} />
        <Route path="/shop" element={<Shop/>} />
      </Routes>
      
      <Footer />
      <Chatbox/>
    </Router>
  );
}

export default App;




