import React,{useState} from "react";
import "../Footer/Footer.css";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

import { MdWifiCalling3 } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";

import Runner from "../Runner/Runner";

const Footer = () => {


  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Please enter a valid email address.");
      return false;
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    
    if (isLoading || isSubscribed) return; // Avoid multiple triggers
    
    if (error || !validateEmail(email)) {
      Swal.fire("Error", "Please enter a valid email address.", "error");
      return;
    }

    setIsLoading(true);
    setIsSubscribed(true);
    const subscriptionMessage = `Message from: Join Squad`;
    try {
      await 
      emailjs.send('service_m6adkcy', 'template_pbmxkai', {email , from: subscriptionMessage}, 'FE_qmfU7NpslQTD_b')

      Swal.fire("Celebrating Our Launch!", "Thanks for joining us! Enjoy a special 5% discount on your first purchase!", "success");
      setEmail("");
    } catch (error) {
      Swal.fire("Error", "Subscription failed. Try again later.", "error");
    } finally {
      setIsLoading(false);
      setIsSubscribed(false);
    }
  };
  const calladmin = () => {
    const phoneNumber = "tel:+91 7394011335";
    window.open(phoneNumber, "_self");
  };
  const handleMailClick = (event) => {
   
    event.preventDefault();

    

   
    window.location.href = "mailto:hello@3eskinroute.com";
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-4 ">
            <div className="row1">
              <h1 className="e-head">
                Join the <span className="e-head-span"> 3ESkinRoute </span>
                Squad
              </h1>
              <p>Subscribe to our newsletter for skincare tips, exclusive
              offers, and early access to new products</p>
              <form className="placehol" onSubmit={handleSubscribe}>
                <input
                  type="text"
                  className="sn"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                  disabled={isSubscribed}
                />
                <button
                className="button-4"
                type="submit"
                disabled={isSubscribed || isLoading}
              >
                {isLoading ? "Subscribing..." : "Subscribe"}
              </button>
                
              </form>
              {emailError && <p className="error-text">{emailError}</p>}
              {/*-
              <div className="col-xl-4 col-md-4 mb-30">
               
                <div className="single-cta">
                  <FaMapMarkerAlt className="footer-icon" />
                  <div className="cta-text">
                    <h4>Find us</h4>
                    <span>Block A ,Burari, Santnagar, Delhi-000000</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <FaPhone className="footer-icon" />
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <span>+91 1234567890</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <FaEnvelopeOpen className="footer-icon" />
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <span>hello@3eskinroute.com</span>
                  </div>
                </div>
              </div>
-*/}
            </div>
          </div>
          <div className="footer-content pt-1 pb-4">
            <div className="row">
              <div className="col-xl-3 col-lg-3 mb-50">
                <div className="footer-widget">
                  {/*-
                <div className="footer-widget-heading">
                    <h3>About Company</h3>
                  </div>
                  -*/}
                  <div className="footer-logo">
                    <Link to="/">
                      <img src="/tlogo.png" className="img-fluid" alt="logo" />
                    </Link>
                  </div>
                  <div className="footer-text">
                   {/*-<p className="ftt">
                      "Nourish Your Skin, Embrace Your Beauty"
                    </p>
                    -*/} 
                    <p>
                      Smile at the glow that mirrors your radiance. Let yourself
                      shine.
                    </p>
                  </div>
                 {/*- <h5 className="add">
                    <FaMapMarkerAlt />
                    {""}
                    {""}Block A ,Burari, Santnagar, Delhi-000000
                  </h5>
                  -*/}

                  {/*-
                  <div className="footer-social-icon">
                    <span>Let's Get Social</span>
                    <ul className="social_icon">
                      <li>
                        <a href="#">
                          <FaFacebookF className="footer-icon1" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaLinkedin className="footer-icon1" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/3e.skinroute/" target="_blank">
                          <FaInstagram className="footer-icon1" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaYoutube className="footer-icon1" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  -*/}
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Posts</h3>
                  </div>
                  <div className="footer-widget-content">
                    <Link className="lll" to="/blog/1">
                      <div className="footer-item">
                        <div className="span-div">
                          <span className="footer-textt">
                            Introduction to Plant-Based Skincare
                          </span>
                          <p className="da-text">3/12/2023</p>
                        </div>
                        <img
                          src="/b1.jpeg"
                          alt="Home"
                          className="footer-icon2"
                        />
                      </div>
                    </Link>
                    <Link className="lll" to="/blog/2">
                      <div className="footer-item">
                        <div className="span-div">
                          <span className="footer-textt">
                            The Benefits of Clean Ingredients
                          </span>
                          <p className="da-text">2/1/2024</p>
                        </div>
                        <img
                          src="/b2.jpeg"
                          alt="Shop"
                          className="footer-icon2"
                        />
                      </div>
                    </Link>
                    <Link className="lll" to="/blog/3">
                      <div className="footer-item">
                        <div className="span-div">
                          <span className="footer-textt">
                            Mild Ingredients for Sensitive Skin
                          </span>
                          <p className="da-text">2/1/2024</p>
                        </div>
                        <img
                          src="/b3.jpeg"
                          alt="Blogs"
                          className="footer-icon2"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3 className="h3-h">Useful Links</h3>
                  </div>
                  <ul className="ul-link">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/shop">Products</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/ingredients">Ingredients</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                   
                    <li>
                      <Link to="/disclaimer">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/consultation">Book Your Consultation</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                <div className="footer-social-icon">
                  <div className="footer-widget-heading">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="t-cm">
                    <h6 className="t-cm-p" onClick={calladmin}>
                      Call us <MdWifiCalling3 className="icon-cm" />
                    </h6>
                    <h6  className="t-cm-p">
                      <a className="t-cm-l" href="mailto:hello@3eskinroute.com">
                        Mail Us
                      </a>
                      <IoMdMailUnread onClick={handleMailClick} className="icon-cm" />
                    </h6>
                  </div>
                  {/*-
                    <ul className="social_icon">
                      <li>
                        <a href="#">
                          <FaFacebookF className="footer-icon1" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaLinkedin className="footer-icon1" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/3e.skinroute/" target="_blank">
                          <FaInstagram className="footer-icon1" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaYoutube className="footer-icon1" />
                        </a>
                      </li>
                    </ul>
                    -*/}
                </div>
                <div className="footer-widget">
                  {/*- <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  -*/}
                  <h4 className="b-h">Working Hours</h4>
                  <h6 className="b-h-s">
                    Monday to Friday: 09:30 - 06:30 
                  </h6>
                  <h6 className="b-h-s">
                    Saturday: 10:00 - 5:00 
                  </h6>
                 

                  {/*-
                  <div className="footer-text mb-25">

                
                    <p>
                      Subscribe to our newsletter for skincare tips, exclusive
                      offers, and early access to new products.{" "}
                    </p>
                 
                  </div>
                  <div className="subscribe-form">
                    <form action="#">
                      <input type="text" className="sn" placeholder="Email Address" />
                      <button>
                        <FaTelegramPlane className="footer-icon" />
                      </button>
                    </form>
                  </div>
                -*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Runner />
        <div className="copyright-area">
          <div className="container">
            <div className="row2">
              <div className=" text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © {currentYear} 3ESkinRoute . All Right Reserved . <img src="tlogo.png" className="tlogo" alt="logo" /> is a registered trademark . Powered by{" "}
                    <a
                      href="https://infogainsoft.com/"
                      className="liii"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Infogainsoft
                    </a>
                  </p>
                </div>
              </div>
              {/*-
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Policy</a>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              -*/}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
