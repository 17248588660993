import React, { useState ,useRef} from "react";
import "../Contact/Contact.css"; 
import {
 
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

import { FaArrowRight } from "react-icons/fa";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Consultation = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSkinType, setSelectedSkinType] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    age: "",
    concern: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);


  // Validation logic
  const validate = (name, value) => {
    let errors = { ...formErrors };
    switch (name) {
      case "name":
        errors.name = /^[A-Za-z\s]+$/.test(value)
          ? ""
          : "Name should contain only alphabets.";
        break;
      case "email":
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address.";
        break;
      case "mobile":
        errors.mobile = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number should be 10 digits.";
        break;
      case "age":
        errors.age =
          value > 17 && value <= 70 ? "" : "Age must be between 18 and 70.";
        break;
      case "concern":
        errors.concern =
          value.length >= 15
            ? ""
            : "Concern should be at least 15 characters long.";
        break;
      default:
        break;
    }
    setFormErrors(errors);
  };

  // Handling form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);
  };

  // Getting dates for the current month, with placeholders for correct day alignment
const getDatesForCurrentMonth = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const dates = [];

  // Adding placeholders for the first day alignment
  for (let i = 0; i < startOfMonth.getDay(); i++) {
    dates.push(null); // null represents a placeholder for days before the 1st
  }

  // Adding the actual dates for the month
  for (
    let day = new Date(startOfMonth);
    day <= endOfMonth;
    day.setDate(day.getDate() + 1)
  ) {
    dates.push(new Date(day));
  }

  return dates;
};

const dates = getDatesForCurrentMonth();
const getFilteredTimes = () => {
  if (!selectedDate) return times; // No date selected, show all times.

  const today = new Date();
  if (selectedDate.toDateString() === today.toDateString()) {
    const currentHour = today.getHours();
    const currentMinute = today.getMinutes();

    return times.filter((time) => {
      // Parse the start time from the slot
      const [start, period] = time.split(" - ")[0].split(" ");
      const [hour, minute] = start.split(":").map(Number);
      const adjustedHour = period === "PM" && hour !== 12 ? hour + 12 : hour;

      // Allow only times later than the current time
      return adjustedHour > currentHour || (adjustedHour === currentHour && minute > currentMinute);
    });
  }

  return times; // Return all times for other dates.
};

  const times = ["10:00 AM - 11:00 AM", "11:00 AM - 12:00 PM", "12:00 PM - 1:00 PM", "1:00 PM - 2:00 PM", "2:00 PM - 3:00 PM","3:00 PM - 4:00 PM"];

  // Date and time selection handlers
  const handleDateChange = (date) => {
    const today = new Date();
    // Reset time only if the new date is different
    if (date.toDateString() !== selectedDate?.toDateString()) {
      setSelectedDate(date);
      setSelectedTime(null); // Reset time when date changes
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleNextClick = () => {
    if (selectedSkinType === "") {
      Swal.fire({
        icon: "warning",
        title: "Skin Type Required",
        text: "Please select your skin type before proceeding.",
      });
      return;
    }
    setShowForm(true); // Show form after clicking Next
  };

  // Form submission handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate all fields before submission
    const errors = {};
    Object.keys(formValues).forEach((key) => {
      validate(key, formValues[key]);
      if (formErrors[key]) {
        errors[key] = formErrors[key];
      }
    });

    if (selectedSkinType === "") {
      Swal.fire({
        icon: "warning",
        title: "Skin Type Required",
        text: "Please select your skin type before submitting the form.",
      });
      setIsSubmitting(false);
      return;
    }

    if (Object.values(errors).some((error) => error !== "")) {
      Swal.fire({
        icon: "error",
        title: "Form Errors",
        text: "Please fix the errors in the form before submitting.",
      });
      setIsSubmitting(false);
      return;
    }

    const formattedDate = selectedDate
      ? selectedDate.toISOString().split("T")[0]
      : "";

    const formData = {
      selected_date: formattedDate,
      selected_time: selectedTime,
      skin_type: selectedSkinType,
      name: formValues.name,
      email: formValues.email,
      mobile: formValues.mobile,
      age: formValues.age,
      concern: formValues.concern,
      from: `Message from: Consultation form`,
    };

    emailjs
      .send(
        "service_m6adkcy",
        "template_o3n51ef",
        formData,
        "FE_qmfU7NpslQTD_b"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Consultation Request Confirmed!",
            text: "Our team will reach out shortly to assist you.",
          });
          // Reset form
          setFormValues({
            name: "",
            email: "",
            mobile: "",
            age: "",
            concern: "",
          });
          setSelectedSkinType("");
          setSelectedDate(null);
          setSelectedTime(null);
          setShowForm(false);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Form submission failed. Please try again.",
          });
        }
      )
      .finally(() => {
        setIsSubmitting(false); // Re-enable the submit button after operation
      });
  };

  const getFormattedDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date ? date.toLocaleDateString(undefined, options) : "";
  };

  const contactRef = useRef(null);

  const handleSelection = (e) => {
    setSelectedSkinType(e.target.value);

    // Scroll to the contact-container
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <section className="point-section">
        <div className="card-pp">
          <h2>Choose your skin type</h2>
          <form>
            <div className="radio-group">
              <label className="point-item">
                <input
                  type="radio"
                  name="skinType"
                  value="Dry Skin"
                  checked={selectedSkinType === "Dry Skin"}
                  onChange={handleSelection}
                />
                
                <span>Dry Skin</span>
                <img
                  src="dry.jpg"
                  alt="Dry Skin"
                  className="point-img"
                />
              </label>

              <label className="point-item">
                <input
                  type="radio"
                  name="skinType"
                  value="Dry Mature"
                  checked={selectedSkinType === "Dry Mature"}
                  onChange={handleSelection}
                />
                <span>Dry Mature</span>
                <img src="drymature.jpg" alt="Dry Mature" className="point-img" />
              </label>

              <label className="point-item">
                <input
                  type="radio"
                  name="skinType"
                  value="Acne Prone"
                  checked={selectedSkinType === "Acne Prone"}
                  onChange={handleSelection}
                />
                <span>Acne Prone</span>
                <img src="acneprone.jpg" alt="Acne Prone" className="point-img" />
              </label>




              
              <label className="point-item">
                <input
                  type="radio"
                  name="skinType"
                  value="Open Pores"
                  checked={selectedSkinType === "Open Pores"}
                  onChange={handleSelection}
                />
                <span>Open Pores</span>
                <img src="openpores.jpg" alt="Open Pores" className="point-img" />
              </label>
              
              <label className="point-item">
                <input
                  type="radio"
                  name="skinType"
                  value="Melasma"
                  checked={selectedSkinType === "Melasma"}
                  onChange={handleSelection}
                />
                <span>Melasma</span>
                <img src="melasma.jpg" alt="Acne Prone" className="point-img" />
              </label>
              
              <label className="point-item">
                <input
                  type="radio"
                  name="skinType"
                  value="Sensitive Skin"
                  checked={selectedSkinType === "Sensitive Skin"}
                  onChange={handleSelection}
                />
                <span>Sensitive Skin</span>
                <img src="sensitiveskin.jpg" alt="Sensitive Skin" className="point-img" />
              </label>
            </div>
          </form>
        </div>
      </section>

      <div className="contact-container" ref={contactRef}>
        {!showForm ? (
          <>
            <div className="left-section">
              <h2 className="h2-u"><span className="h2-u-span"></span> Free Skin Consultation</h2>
              <p>
                Get a step closer to understanding your constantly changing skin
                and skin needs with our free consultations.
              </p>
              <p>Please select a date and time for your consultation.</p>
              <div className="call-us">
                {/*-  <IoTimerSharp className="icon" /> 15 min-*/}
              </div>
              <div className="social-icons">
              <a
                  href="https://www.facebook.com/people/3E-Skinroute/61565904743428/?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <FaFacebookF className="icon" />
                </a>
                <a
                  href="https://www.instagram.com/3e.skinroute/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <FaInstagram className="icon" />
                </a>
                <a
                  href="https://www.linkedin.com/in/silkychopra3eskinroute/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <FaLinkedin className="icon" />
                </a>
              </div>
            </div>

            <div className="calendar-section">
            <div className="d-f">
                <h2 className="h2-u">
                  Select a Date & Time {new Date().getFullYear()}
                </h2>

                <div className="days-of-week">
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                    (day, index) => (
                      <div key={index} className="day-name">
                        {day}
                      </div>
                    )
                  )}
                </div>

                <div className="calendar">
  {dates.map((date, index) => {
    if (!date) {
      // Render an empty cell for placeholders
      return <div key={index} className="date placeholder"></div>;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to the start of today
    const isPastDate = date < today; // Check if the date is in the past
    const isSunday = date.getDay() === 0; // Check if the date is a Sunday

    return (
      <div
        key={index}
        className={`date ${isPastDate || isSunday ? "disabled" : ""} ${
          selectedDate && selectedDate.toDateString() === date.toDateString()
            ? "selected"
            : ""
        }`}
        onClick={() => {
          // Only allow click if date is not past and not a Sunday
          if (!isPastDate && !isSunday) {
            handleDateChange(date);
          }
        }}
      >
        {date.getDate()}
      </div>
    );
  })}
</div>



</div>

{selectedDate && (
  <div className="time-section-horizontal">
  <h2 className="h2-u">Select Time</h2>
  <div className="time-slots-container">
    {times.map((time, index) => {
      const today = new Date();
      const [start, period] = time.split(" - ")[0].split(" ");
      const [hour, minute] = start.split(":").map(Number);
      const adjustedHour = period === "PM" && hour !== 12 ? hour + 12 : hour;

      const isPastTime =
        selectedDate &&
        selectedDate.toDateString() === today.toDateString() &&
        (adjustedHour < today.getHours() ||
          (adjustedHour === today.getHours() && minute <= today.getMinutes()));

      return (
        <div key={index} className="time-slot-wrapper">
          <div
            className={`time-slot ${selectedTime === time ? "selected" : ""} ${
              isPastTime ? "disabled" : ""
            }`}
            onClick={() => !isPastTime && handleTimeSelect(time)}
          >
            {time}
          </div>
          {selectedTime === time && (
            <div className="but-dd">
              <button className="next-buttonn" onClick={handleNextClick}>
                Next <FaArrowRight className="ne-ic" />
              </button>
            </div>
          )}
        </div>
      );
    })}
  </div>
</div>

)}
</div>
          </>
        ) : (
          <div className="form-container">
            <div className="left-section">
              <h2 className="h2-u"> Free Skin Consultation</h2>
              <p>
                Get a step closer to understanding your constantly changing skin
                and skin needs with our free consultations.
              </p>
              <p>Please Fill the Required input fields </p>
              <div className="selected-details">
                <p>
                  <strong>Date:</strong> {getFormattedDate(selectedDate)}
                </p>
                <p>
                  <strong>Time:</strong> {selectedTime}
                </p>
              </div>
              <p>
                <strong>Skin Type:</strong> {selectedSkinType}
              </p>
              <div className="call-us">
                {/*- <IoTimerSharp className="icon" /> 15 min-*/}
              </div>
              <div className="social-icons">
                <a href="https://www.facebook.com/people/3E-Skinroute/61565904743428/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                  {" "}
                  <FaFacebookF className="icon" />
                </a>

                <a
                  href="https://www.instagram.com/3e.skinroute/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <FaInstagram className="icon" />
                </a>
                <a href="https://www.linkedin.com/in/silkychopra3eskinroute/" target="_blank" rel="noopener noreferrer">
                  {" "}
                  <FaLinkedin className="icon" />
                </a>
              </div>
            </div>

            <div className="form-section">
              <form onSubmit={handleFormSubmit}>
                <h3 className="h2-u">Fill in Your Details</h3>
                <input
                  type="hidden"
                  name="selected_date"
                  value={selectedDate}
                />
                <input
                  type="hidden"
                  name="selected_time"
                  value={selectedTime}
                />
                <input
                  type="hidden"
                  name="skin_type"
                  value={selectedSkinType}
                />
                <div className="fff2">
                  <label>
                    Name:
                    <input
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      autoComplete="off"
                      required
                    />
                    {formErrors.name && (
                      <p className="error">{formErrors.name}</p>
                    )}
                  </label>
                  <label>
                    Email:
                    <input
                      type="email"
                      name="email"
                      autoComplete="off"
                      value={formValues.email}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.email && (
                      <p className="error">{formErrors.email}</p>
                    )}
                  </label>
                </div>

                <div className="fff2">
                  <label>
                    Mobile:
                    <input
                      type="tel"
                      autoComplete="off"
                      name="mobile"
                      value={formValues.mobile}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.mobile && (
                      <p className="error">{formErrors.mobile}</p>
                    )}
                  </label>
                  <label>
                    Age:
                    <input
                      type="number"
                      name="age"
                      autoComplete="off"
                      value={formValues.age}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.age && (
                      <p className="error">{formErrors.age}</p>
                    )}
                  </label>
                </div>

                <label>
                  Your Concern:
                  <textarea
                    name="concern"
                    rows="4"
                    autoComplete="off"
                    value={formValues.concern}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                  {formErrors.concern && (
                    <p className="error">{formErrors.concern}</p>
                  )}
                </label>

                <button
                  type="submit"
                  className="submit-buttonc"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Consultation;
